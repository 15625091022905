<template>
    <div>
        <nav id="navbar" class="fixed-top navbar shadow  navbar-expand-lg navbar-light bg-light justify-content-between
		justify-content-sm-start navbar-light bg-color-custom p-0">
            <div class="container">
                <a class="navbar-brand"><img id="logo" class="img-navbar" style="height:85px;cursor:pointer"
                        src="img/logoTioToto.png" @click="this.redirectHome()"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse d-md-flex d-lg-flex" id="navbarNavAltMarkup">
                    <div class="navbar-nav">
                        <a class="navbar-item nav-link lift "  @click="redirectPedidos()"> Mis Pedidos</a>
                        <a class="navbar-item nav-link lift " @click="redirectPrice()"> Lista de Precios</a>
                        <a class="navbar-item nav-link lift " @click="redirectFrequentQuestion()"> Preguntas
                            Frecuentes</a>
                        <a class="navbar-item nav-link lift "  @click="redirectAboutUS()"> Quienes Somos</a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>

import router from '../router/index'

export default {
    name: 'Header',
    methods: {
    },
    computed: {
        redirectPedidos(){
            location.href = "/pedidos"
        },
        redirectHome(){
            location.href = "/"
        },
        redirectPrice(){
            location.href = "/price";
        },
        redirectAboutUS(){
            location.href = "/about";
        },
        redirectFrequentQuestion(){
            location.href = "/frequentQuestion";
        }
    },
    props: {
        step: String,
    }
}
</script>

<style lang="css" scoped>


</style>
