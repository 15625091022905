
const getDefaultState = () => {
    return {
            send:[]
    }
}

const state = getDefaultState();

//Actions
const actions = {
    setOrder({commit}, payload){
        commit('SET_ORDER', payload)
    },
    setRemoveOrder({commit}, payload){
        commit('SET_REMOVE_ORDER', payload)
    },
}
//Getters
const getters = {}
//Mutations
const mutations = {
    SET_ORDER(state, payload){
        state.send.push(payload);
    },
    SET_REMOVE_ORDER(state, payload){
        var obj = state.send.files.findIndex((obj) => obj.name === payload);
        state.send.files.splice(obj);
    },

}


const cotizacion = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default cotizacion