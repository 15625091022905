import { createWebHistory, createRouter } from "vue-router";
import BaseLayout from "../layouts/BaseLayout.vue";
import MobileLayout from "../layouts/MobileLayout.vue";
const routes = [
  {
    path: "/",
    name: "BaseLayout",
    component: BaseLayout,
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('../views/index.vue'),
      },
      {
        path: '/tiototosuccess',
        name: 'tiototosuccess',
        component: () => import('../views/success.vue'),
      },
      {
        path: '/tiototofailure',
        name: 'tiototofailure',
        component: () => import('../views/error.vue'),
      },
      {
        path: '/price',
        name: 'price',
        component: () => import('../views/price.vue'),
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about.vue'),
      },
      {
        path: '/frequentQuestion',
        name: 'frequentQuestion',
        component: () => import('../views/frequentQuestion.vue'),
      },
      {
        path: '/redes',
        name: 'redes',
        component: () => import('../views/redes.vue'),
      },
      {
        path: '/pedidos',
        name: 'pedidos',
        component: () => import('../views/pedidos.vue'),
      },
 
    ],
  },
  {
    path: "/",
    name: "MobileLayout",
    component: MobileLayout,
    children: [
      {
        path: '/menu',
        name: 'menu',
        component: () => import('../views/menu.vue'),
      }
    ]
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;