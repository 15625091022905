
const getDefaultState = () => {
    return {
        send:{
            hojas:"",
            hojasDesc:"",
            calidad:"",
            calidadDesc:"",
            formato:"",
            formatoDesc:"",
            faz:"",
            fazDesc:"",
            tipoPapel:"",
            tipoPapelDesc:"",
            terminado:"",
            terminadoDesc:"",
            agrupado:"",
            agrupadoDesc:"",
            portada:"",
            cantidad:1,
            files:[],
        },
        list:{
            listHojas:"",
            listFormato:"",
            listCalidad:"",
            listFaz:"",
            listTipoPapel:"",
            listTerminado:""
        },
    }
}

const state = getDefaultState();

//Actions
const actions = {
    setListHojas({commit}, payload){
        commit('SET_LIST_HOJAS', payload)
    },
    setListFormato({commit}, payload){
        commit('SET_LIST_FORMATO', payload)
    },
    setListCalidad({commit}, payload){
        commit('SET_LIST_CALIDAD', payload)
    },
    setListFaz({commit}, payload){
        commit('SET_LIST_FAZ', payload)
    },
    setListTipoPapel({commit}, payload){
        commit('SET_LIST_PAPEL', payload)
    },
    setListTerminado({commit}, payload){
        commit('SET_LIST_TERMINADO', payload)
    },

    setHojas({commit}, payload){
        commit('SET_HOJAS', payload)
    },
    setHojasDesc({commit}, payload){
        commit('SET_HOJAS_DESC', payload)
    },
    setFormato({commit}, payload){
        commit('SET_FORMATO', payload)
    },
    setFormatoDesc({commit}, payload){
        commit('SET_FORMATO_DESC', payload)
    },
    setCalidad({commit}, payload){
        commit('SET_CALIDAD', payload)
    },
    setCalidadDesc({commit}, payload){
        commit('SET_CALIDAD_DESC', payload)
    },
    setFaz({commit}, payload){
        commit('SET_FAZ', payload)
    },
    setFazDesc({commit}, payload){
        commit('SET_FAZ_DESC', payload)
    },
    setPapel({commit}, payload){
        commit('SET_PAPEL', payload)
    },
    setPapelDesc({commit}, payload){
        commit('SET_PAPEL_DESC', payload)
    },
    setTerminado({commit}, payload){
        commit('SET_TERMINADO', payload)
    },
    setTerminadoDesc({commit}, payload){
        commit('SET_TERMINADO_DESC', payload)
    },
    setAgrupado({commit}, payload){
        commit('SET_AGRUPADO', payload)
    },
    setAgrupadoDesc({commit}, payload){
        commit('SET_AGRUPADO_DESC', payload)
    },
    setPortada({commit}, payload){
        commit('SET_PORTADA', payload)
    },
    setCantidad({commit}, payload){
        commit('SET_CANTIDAD', payload)
    },
    setAddFile({commit}, payload){
        commit('SET_ADD_FILE', payload)
    },
    setRemoveFile({commit}, payload){
        commit('SET_REMOVE_FILE', payload)
    },
    
    //reset
    setResetHojas({commit}, payload){
        commit('SET_RESET_HOJAS', payload)
    },
    setResetCalidad({commit}, payload){
        commit('SET_RESET_CALIDAD', payload)
    },
}

//Getters
const getters = {
 
}

//Mutations
const mutations = {
    SET_LIST_HOJAS(state, payload){
        state.list.listHojas=payload;
    },
    SET_LIST_FORMATO(state, payload){
        state.list.listFormato=payload;
    },
    SET_LIST_CALIDAD(state, payload){
        state.list.listCalidad=payload;
    },
    SET_LIST_FAZ(state, payload){
        state.list.listFaz=payload;
    },
    SET_LIST_PAPEL(state, payload){
        state.list.listTipoPapel=payload;
    },
    SET_LIST_TERMINADO(state, payload){
        state.list.listTerminado=payload;
    },

    SET_HOJAS(state, payload){
        state.send.hojas=payload;
    },
    SET_HOJAS_DESC(state, payload){
        state.send.hojasDesc=payload;
    },
    SET_FORMATO(state, payload){
        state.send.formato=payload;
    },
    SET_FORMATO_DESC(state, payload){
        state.send.formatoDesc=payload;
    },
    SET_CALIDAD(state, payload){
        state.send.calidad=payload;
    },
    SET_CALIDAD_DESC(state, payload){
        state.send.calidadDesc=payload;
    },
    SET_FAZ(state, payload){
        state.send.faz=payload;
    },
    SET_FAZ_DESC(state, payload){
        state.send.fazDesc=payload;
    },
    SET_PAPEL(state, payload){
        state.send.tipoPapel=payload;
    },
    SET_PAPEL_DESC(state, payload){
        state.send.tipoPapelDesc=payload;
    },
    SET_TERMINADO(state, payload){
        state.send.terminado=payload;
    },
    SET_TERMINADO_DESC(state, payload){
        state.send.terminadoDesc=payload;
    },
    SET_AGRUPADO(state, payload){
        state.send.agrupado=payload;
    },
    SET_AGRUPADO_DESC(state, payload){
        state.send.agrupadoDesc=payload;
    },
    SET_PORTADA(state, payload){
        state.send.portada=payload;
    },
    SET_CANTIDAD(state, payload){
        state.send.cantidad=payload;
    },
    SET_ADD_FILE(state, payload){
        state.send.files.push(payload);
    },
    SET_REMOVE_FILE(state, payload){
        var obj = state.send.files.findIndex((obj) => obj.name === payload);
        state.send.files.splice(obj);
    },
    
    //reset
    SET_RESET_HOJAS(state){
        state.send.calidad="";
        state.send.formato="";
        state.send.faz="";
        state.send.tipoPapel="";
        state.send.terminado="";
        state.send.agrupado="";
        state.send.portada="";

        state.list.listFormato="",
        state.list.listCalidad="",
        state.list.listFaz="",
        state.list.listTipoPapel="",
        state.list.listTerminado=""
    },

    SET_RESET_CALIDAD(state){
        state.send.faz="";
        state.send.tipoPapel="";
        state.send.terminado="";
        state.send.agrupado="";
        state.send.portada="";

        state.list.listFaz="",
        state.list.listTipoPapel="",
        state.list.listTerminado=""
    }
}


const cotizacion = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default cotizacion