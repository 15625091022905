<template>
    <div>
      <main>
          <div class="base">
              <div class="base__content">
                  <router-view :key="$route.fullPath" />
              </div>
          </div>
      </main>
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  export default {
    name: 'BaseLayout',
    components: {
      Header,
      Footer
    },
    mounted() {
    },
    computed:{
      loading(){
        return this.$store.state.loading;
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>