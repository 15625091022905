
const getDefaultState = () => {
    return {
        id:"",
        name:"",
        lastName:"",
        phone:"",
        email:"",
        emailConfirmation:""
    }
}

const state = getDefaultState();

//Actions
const actions = {
    setID({commit}, payload){
        commit('SET_ID', payload)
    },
    setName({commit}, payload){
        commit('SET_NAME', payload)
    },
    setLastName({commit}, payload){
        commit('SET_LAST_NAME', payload)
    },
    setPhone({commit}, payload){
        commit('SET_PHONE', payload)
    },
    setEmail({commit}, payload){
        commit('SET_EMAIL', payload)
    },
    setEmailConfirmation({commit}, payload){
        commit('SET_EMAIL_CONFIRMATION', payload)
    },
    validatePersonData(){
        if(state.name == ""){throw "El nombre es obligatorio"}
        if(state.lastName == ""){throw "El apellido es obligatorio"}
        if(state.phone == ""){throw "El celular es obligatorio"}
        if(state.email == ""){throw "El email es obligatorio"}
        if(state.emailConfirmation == ""){throw "La confirmación del email es obligatorio"}
    },
}

//Getters
const getters = {
 
}

//Mutations
const mutations = {
    SET_ID(state, payload){
        state.id=payload;
    },
    SET_NAME(state, payload){
        state.name=payload;
    },
    SET_LAST_NAME(state, payload){
        state.lastName=payload;
    },
    SET_PHONE(state, payload){
        state.phone=payload;
    },
    SET_EMAIL(state, payload){
        state.email=payload;
    },
    SET_EMAIL_CONFIRMATION(state, payload){
        state.emailConfirmation=payload;
    },
    
}


const cotizacion = {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}


export default cotizacion