import { createStore } from "vuex";
import person from "./modules/person"
import dataOrder from "./modules/dataOrder"
import orderSend from "./modules/orderSend"
export default createStore({
  state: {
    loading:false,
    loadingFile:true,
    order:[],
  },
  getters: {
  },
  mutations: {
    SET_SPINNER(state, payload){
      state.loading=payload;
    },
    SET_SPINNER_FILE(state, payload){
      state.loadingFile=payload;
    },
    SET_ADD_ORDER(state, payload){
      state.loading.push(payload);
    },
  },

  actions: {
    setSpinner({commit}, payload){
      commit('SET_SPINNER', payload)
    },
    setSpinnerFile({commit}, payload){
      commit('SET_SPINNER_FILE', payload)
    },
    addOrder({commit}, payload){
      commit('SET_ADD_ORDER', payload)
    },
  },
  modules: {
    person,dataOrder,orderSend
  }
});



//Module