<template>
  <div>
   <Header />
      <div v-show="this.loading" id="spinner" style="position: fixed; z-index: 9999; top: 0px; left: 0px; width: 100%; height: 100%; background: grey; opacity: 0.5;">
            <button class="spinner-btn">
                <span class="spinner"></span>
                <span class="btn__text">Cargando...</span>
            </button>
      </div>
    <main>
        <div class="base">
            <div class="base__content">
                <router-view :key="$route.fullPath" />
            </div>
        </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'BaseLayout',
  components: {
    Header,
    Footer
  },
  mounted() {
  },
  computed:{
    loading(){
      return this.$store.state.loading;
    }
  },
}
</script>

<style lang="scss" scoped>

</style>